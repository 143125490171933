import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'
import $p from '@/plugins/permission/permissionMaps'

import {
  // GAME_MENU,
  RESOURCES_MENU,
  // ADVERTISING_SPACE,
  APP_REVIEW_MANAGEMENT
} from '@/router/asyncRoutes/home/routesData'

export default [
  {
    path: 'home',
    alias: '/',
    component: LayoutIndex,
    redirect: '/home/dashboard',
    meta: {
      title: 'Home',
      isLayout: true
    },
    children: [
      {
        path: '/home/dashboard',
        component: LayoutMain,
        children: [
          {
            path: '/home/dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Project',
              icon: 'dashboard'
            },
            component: () => import('@/views/dashboard/Dashboard')
          }
        ]
      },
      {
        path: 'data-centre',
        name: '数据中心',
        meta: {
          title: '数据中心',
          icon: 'bar-chart-o'
        },
        component: LayoutMain,
        alwaysShow: true,
        children: [
          {
            path: 'product-operations',
            name: '运营数据',
            meta: {
              title: '运营数据'
              // icon: 'line-chart'
            },
            component: LayoutIndex,
            alwaysShow: true,
            children: [
              {
                path: 'revenue',
                name: '各国营收查询',
                meta: {
                  title: '各国营收查询'
                },
                component: () => import('@/views/finance/product-operations/revenue')
              },
              {
                path: 'bi',
                name: '运营数据(BI)',
                meta: {
                  title: '运营数据(BI)'
                },
                component: () => import('@/views/finance/product-operations/bi')
              },
              {
                path: 'game-data',
                name: '游戏使用情况',
                meta: {
                  title: '游戏使用情况'
                },
                component: () => import('@/views/finance/product-operations/game-data/index.vue')
              },
              {
                path: 'user-paid-conversion',
                name: '新增付费转化率',
                meta: {
                  title: '新增付费转化率'
                },
                component: () => import('@/views/finance/product-operations/PaidConversionRate')
              },
              {
                path: 'user-active-conversion',
                name: '活跃付费转化率',
                meta: {
                  title: '活跃付费转化率'
                },
                component: () => import('@/views/finance/product-operations/ActiveConversionRate')
              },

              // {
              //   path: 'game-attracts-money-ability',
              //   name: '游戏吸金能力',
              //   meta: {
              //     title: '游戏吸金能力'
              //   },
              //   component: () => import('@/views/finance/product-operations/GameAttractsMoneyAbility')
              // },
              {
                path: 'payment-method-conversion-rate',
                name: '支付付费转化率',
                meta: {
                  title: '支付付费转化率'
                },
                component: () => import('@/views/finance/product-operations/PaymentMethodConversionRate')
              },
              {
                path: 'streaming-desktop',
                name: '服务使用留存',
                meta: {
                  title: '服务使用留存'
                },
                component: () => import('@/views/finance/product-operations/Dashboard')
              },
              {
                path: 'subscription-data-query',
                name: '订阅数据查询',
                meta: {
                  title: '订阅数据查询'
                },
                component: () => import('@/views/finance/product-operations/SubscriptionDataQuery')
              }
            ]
          },
          // {
          //   path: 'vr-finance',
          //   name: 'VR数据',
          //   meta: {
          //     title: 'VR数据'
          //     // icon: 'pie-chart'
          //   },
          //   component: LayoutIndex,
          //   alwaysShow: true,
          //   children: [
          //     {
          //       path: 'vr-user-experience-payment-conversion-rate',
          //       name: 'VR用户体验转化率',
          //       meta: {
          //         title: 'VR用户体验转化率'
          //       },
          //       component: () => import('@/views/finance/vr-analysis/VrUserExperiencePaymentConversionRate')
          //     },
          //     {
          //       path: 'user-paid-conversion',
          //       name: '新增付费转化率',
          //       meta: {
          //         title: '新增付费转化率'
          //       },
          //       component: () => import('@/views/finance/vr-analysis/PaidConversionRate')
          //     },
          //     {
          //       path: 'user-active-conversion',
          //       name: '活跃付费转化率',
          //       meta: {
          //         title: '活跃付费转化率'
          //       },
          //       component: () => import('@/views/finance/vr-analysis/ActiveConversionRate')
          //     },
          //     {
          //       path: 'payment-method-conversion-rate',
          //       name: '支付付费转化率',
          //       meta: {
          //         title: '支付付费转化率'
          //       },
          //       component: () => import('@/views/finance/vr-analysis/PaymentMethodConversionRate')
          //     }
          //     // {
          //     //   path: 'vR-user-order-analysis',
          //     //   name: 'VR用户订单分析',
          //     //   meta: {
          //     //     title: 'VR用户订单分析'
          //     //   },
          //     //   component: () => import('@/views/finance/vr-analysis/VRUserOrderAnalysis')
          //     // },
          //   ]
          // },
          {
            path: 'finance',
            component: LayoutIndex,
            alwaysShow: true,
            meta: {
              title: '数据分析'
              // icon: 'bar-chart-o'
            },
            children: [
              // {
              //   path: 'goal',
              //   name: 'Goal',
              //   meta: {
              //     title: 'Goal'
              //   },
              //   component: () => import('@/views/finance/goal/index.vue')
              // },
              {
                path: 'library',
                name: 'steam绑定查询',
                meta: {
                  title: 'steam绑定查询'
                },
                component: () => import('@/views/finance/library/index.vue')
              },
              {
                path: 'market',
                name: 'Market',
                meta: {
                  title: 'Market'
                },
                component: LayoutIndex,
                children: [
                  {
                    path: 'agency',
                    name: 'Agency',
                    meta: {
                      title: 'Agency'
                    },
                    component: () => import('@/views/finance/market/agency')
                  },
                  {
                    path: 'market-cost',
                    name: 'MarketCost',
                    meta: {
                      title: 'Cost'
                    },
                    component: () => import('@/views/finance/market/cost')
                  }
                ]
              },
              {
                path: 'operation',
                name: 'Operation',
                meta: {
                  title: 'Operation'
                },
                component: LayoutIndex,
                alwaysShow: true,
                children: [
                  {
                    path: 'operation-cost',
                    name: 'OperationCost',
                    meta: {
                      title: 'Cost'
                    },
                    component: () => import('@/views/finance/operation/cost')
                  }
                ]
              }
              // {
              //   path: 'KOL',
              //   name: 'KOL',
              //   meta: {
              //     title: 'kol'
              //   },
              //   component: LayoutIndex,
              //   alwaysShow: true,
              //   children: [
              //     {
              //       path: 'KOL-post',
              //       name: 'KOL-post',
              //       meta: {
              //         title: '录入数据'
              //       },
              //       component: () => import('@/views/finance/kol/KOLPost')
              //     },
              //     {
              //       path: 'KOL-list',
              //       name: 'KOL-list',
              //       meta: {
              //         title: '查看数据'
              //       },
              //       component: () => import('@/views/finance/kol/KOLList')
              //     }
              //   ]
              // }
            ]
          }
        ]
      },
      // GAME_MENU,
      // {
      //   path: 'GameMagnage',
      //   redirect: 'game-library',
      //   name: 'GameMagnage',
      //   alwaysShow: true,
      //   meta: {
      //     title: '游戏管理',
      //     icon: 'gamepad'
      //   },
      //   component: LayoutMain,
      //   children: [
      //     // {
      //     //   path: 'game-library',
      //     //   name: 'GameLibrary',
      //     //   meta: {
      //     //     title: '游戏库'
      //     //   },
      //     //   component: () => import('@/views/game-library/GameLibrary')
      //     // },
      //     {
      //       path: 'game-library-edit',
      //       name: 'GameLibraryEdit',
      //       hidden: true,
      //       meta: {
      //         title: '游戏编辑'
      //       },
      //       component: () => import('@/views/game-library/GameDetail')
      //     },
      //     {
      //       path: 'tag-manage',
      //       name: 'TagManage',
      //       meta: {
      //         title: '标签库'
      //       },
      //       component: () => import('@/views/game-library/TagManage')
      //     }
      //   ]
      // },
      // 运营中心
      {
        path: 'operational-tool',
        name: '运营工具',
        meta: {
          title: '运营工具',
          icon: 'pie-chart'
        },
        component: LayoutMain,
        alwaysShow: true,
        children: [
          RESOURCES_MENU,
          {
            path: 'CouponsAndRedeemCode',
            component: LayoutIndex,
            name: 'CouponsAndRedeemCode',
            alwaysShow: true,
            meta: {
              title: '券码管理'
              // icon: 'barcode'
            },
            children: [
              {
                path: 'RedeemCode',
                name: 'RedeemCode',
                meta: {
                  title: '兑换码'
                },
                component: () => import('@/views/coupons-and-redeem-code/redeem-code/index.vue')
              },
              {
                path: 'RedeemCodeCreate',
                name: 'RedeemCodeCreate',
                hidden: true,
                meta: {
                  title: '新建兑换码',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/redeem-code/CreateOrEditRedeemCode.vue')
              },
              {
                path: 'RedeemCodeEdit',
                name: 'RedeemCodeEdit',
                hidden: true,
                meta: {
                  title: '编辑兑换码',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/redeem-code/CreateOrEditRedeemCode.vue')
              },
              {
                path: 'RedeemCodeList',
                name: 'RedeemCodeList',
                hidden: true,
                meta: {
                  title: '兑换码列表',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/redeem-code/RedeemCodeList.vue')
              },
              {
                path: 'InvitationCode',
                name: 'InvitationCode',
                meta: {
                  title: '邀请码'
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code/index.vue')
              },
              {
                path: 'InvitationCodeCreate',
                name: 'InvitationCodeCreate',
                hidden: true,
                meta: {
                  title: '新建邀请码',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code/CreateOrEditInvitationCode.vue')
              },
              {
                path: 'channelManagement',
                name: 'channelManagement',
                hidden: true,
                meta: {
                  title: '渠道管理',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code/channelManagement.vue')
              },

              {
                path: 'InvitationCodeEdit',
                name: 'InvitationCodeEdit',
                hidden: true,
                meta: {
                  title: '编辑邀请码',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code/CreateOrEditInvitationCode.vue')
              },
              {
                path: 'InvitationList',
                name: 'InvitationList',
                hidden: true,
                meta: {
                  title: '邀请列表',
                  noCache: true
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code/InvitationList.vue')
              },
              {
                path: 'ExternalKol',
                name: 'ExternalKol',
                meta: {
                  title: '外部推广'
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code-external-kol/index.vue')
              },
              {
                path: 'ExternalKolList',
                name: 'ExternalKolList',
                hidden: true,
                meta: {
                  title: '外部推广(列表)'
                },
                component: () => import('@/views/coupons-and-redeem-code/invitation-code-external-kol/InvitationList.vue')
              }
            ]
          },
          {
            path: 'Messagepush',
            // redirect: 'game-library',
            name: 'Messagepush',
            alwaysShow: true,
            meta: {
              title: '消息推送'
              // icon: 'gamepad'
            },
            component: LayoutIndex,
            children: [
              {
                path: 'Messagepush',
                name: 'Messagepush',
                meta: {
                  title: '消息推送'
                },
                component: () => import('@/views/Messagepush/index')
              },
              {
                path: 'Messagerecord',
                name: 'Messagerecord',
                meta: {
                  title: '推送记录'
                },
                component: () => import('@/views/Messagepush/Messagerecord')
              }
            ]
          },
          {
            path: 'advertising-position',
            name: 'advertising-position',
            alwaysShow: true,
            meta: {
              title: '广告管理'
            },
            component: LayoutIndex,
            children: [
              {
                path: 'advertising-space',
                component: () => import('@/views/operational-tool/advertising-space.vue'),
                name: 'AdvertisingPositionManagement',
                meta: {
                  noCache: true,
                  title: '原生广告管理'
                }
              },
              {
                path: 'incentive-advertising',
                name: 'incentive-advertising',
                meta: {
                  title: '激励广告管理'
                },
                component: () => import('@/views/advertising-position/incentive-advertising/index.vue')
              }, {
                path: 'incentive-edit',
                name: 'incentive-edit',
                hidden: true,
                meta: {
                  title: '新增编辑'
                },
                component: () => import('@/views/advertising-position/incentive-advertising/new-edit.vue')
              }
            ]
          },
          {
            path: 'FeedbackSystem',
            // redirect: 'game-library',
            name: 'FeedbackSystem',
            alwaysShow: true,
            meta: {
              title: '用户反馈系统'
              // icon: 'gamepad'
            },
            component: LayoutIndex,
            children: [
              {
                path: 'FeedbackSystem',
                name: 'FeedbackSystem',
                meta: {
                  title: '用户反馈'
                },
                component: () => import('@/views/FeedbackSystem/UserSystem')
              },
              {
                path: 'FeedbackSystemEdit',
                name: 'FeedbackSystemEdit',
                meta: {
                  title: '消息回复'
                },
                hidden: true,
                component: () => import('@/views/FeedbackSystem/FeedbackSystemEdit')
              },
              {
                path: 'questionType',
                name: 'questionType',
                meta: {
                  title: '反馈问题类型'
                },
                component: () => import('@/views/FeedbackSystem/questionType')
              },
              {
                path: 'FeedbackV2',
                name: 'FeedbackV2',
                meta: {
                  title: '订单调研反馈'
                },
                component: () => import('@/views/FeedbackSystem/FeedbackV2')
              }
            ]
          },

          {
            path: 'order-management',
            component: () => import('@/views/transaction-management/order-management'),
            name: 'TransactionManagement',
            meta: {
              title: '交易查询',
              noCache: true
            }
            // children: [1111
            //   // {
            //   //   path: 'index',
            //   //   component: () => import('@/views/transaction-management/index'),
            //   //   name: 'TransactionManagement',
            //   //   meta: {
            //   //     title: 'VR已完成订单',
            //   //     noCache: true
            //   //   }
            //   // },
            //   {
            //     path: 'order-management',
            //     component: () => import('@/views/transaction-management/order-management'),
            //     name: 'TransactionManagement',
            //     meta: {
            //       title: 'NB已完成订单',
            //       noCache: true
            //     }
            //   }
            //   // {
            //   //   path: 'OrderList',
            //   //   component: () => import('@/views/trade-management/OrderList'),
            //   //   name: 'OrderList',
            //   //   meta: {
            //   //     title: 'NB所有订单'
            //   //   }
            //   // }
            // ]
          },
          {
            path: 'UserList',
            component: () => import('@/views/operational-tool/UserList'),
            name: 'UserList',
            meta: {
              title: 'UserList'
            }
          },

          {
            path: 'recharge',
            name: 'AccountRecharge',
            meta: {
              title: 'AccountRecharge'
            },
            component: () => import('@/views/operational-tool/Recharge')
          },
          // ADVERTISING_SPACE,

          {
            path: 'testUser',
            component: () => import('@/views/operational-tool/test-user.vue'),
            name: 'testUser',
            meta: {
              title: '测试账号标记',
              noCache: true
            }
          }
        ]
      },
      // {
      //   path: 'user-management',
      //   redirect: 'UserList',
      //   name: 'userManagement',
      //   alwaysShow: true,
      //   meta: {
      //     title: 'userManagement',
      //     icon: 'address-book'
      //   },
      //   component: LayoutMain,
      //   children: [
      //     // {
      //     //   path: 'UserList',
      //     //   component: () => import('@/views/user-management/UserList'),
      //     //   name: 'UserList',
      //     //   meta: {
      //     //     title: 'UserList'
      //     //   }
      //     // },
      //     {
      //       path: 'recharge',
      //       name: 'AccountRecharge',
      //       meta: {
      //         title: 'AccountRecharge'
      //       },
      //       component: () => import('@/views/recharge-system/Recharge')
      //     }
      //   ]
      // },
      {
        path: 'version-tool',
        name: 'version-tool',
        alwaysShow: true,
        meta: {
          title: '版本工具',
          icon: 'play-circle'
        },
        component: LayoutMain,
        children: [
          APP_REVIEW_MANAGEMENT,
          {
            path: 'index',
            component: () => import('@/views/version-tool/index'),
            name: 'index',
            meta: {
              title: '应用管理'
            }
          },
          {
            path: 'update',
            component: () => import('@/views/version-tool/update'),
            name: 'update',
            meta: {
              title: '应用版本更新'
            }
          },
          {
            path: 'forced',
            component: () => import('@/views/version-tool/forced'),
            name: 'forced',
            meta: {
              title: '强制更新配置'
            }
          }
        ]
      },
      {
        path: 'game-content',
        name: 'game-content',
        alwaysShow: true,
        meta: {
          title: '游戏内容',
          icon: 'gamepad'
        },
        component: LayoutMain,
        children: [
          {
            path: 'rental-number',
            name: 'rental-number',
            alwaysShow: true,
            meta: {
              title: '租号系统'
              // icon: 'balance-scale'
            },
            component: LayoutIndex,
            children: [
              {
                path: 'accountGroup',
                name: 'accountGroup',
                meta: {
                  title: 'AccountGroup'
                },
                component: () => import('@/views/resources/account-group/Dashboard')
              },
              {
                path: 'rent-account',
                name: 'RentAccount',
                // hidden: true,
                meta: {
                  title: 'RentAccount'
                },
                component: () => import('@/views/resources/account-group/RentAccount')
              },
              {
                path: 'rent-account-log',
                name: 'RentAccountLog',
                // hidden: true,
                meta: {
                  title: 'RentAccountLog'
                },
                component: () => import('@/views/resources/account-group/RentAccountLog')
              }
            ]
          },
          {
            path: 'game-library',
            name: 'GameLibrary',
            meta: {
              title: 'steam游戏目录'
            },
            component: () => import('@/views/game-content/GameLibrary')
          },
          // GameMenu,
          {
            path: 'game-list',
            name: 'GameList',
            component: () => import('@/views/game/GameList.vue'),
            meta: {
              title: 'GameList',
              // skeleton: true,
              permission: [
                $p.game.base,
                $p.game.addGame,
                $p.game.editGame
              ]
            }
          },
          {
            path: 'game-recommendation',
            name: 'GameRecommendation',
            meta: {
              title: '游戏推荐'
            },
            component: () => import('@/views/game-content/GameRecommendation.vue')
          },
          {
            path: 'game-create',
            name: 'GameCreate',
            hidden: true,
            meta: {
              title: 'GameCreate',
              // noCache: true,
              // hidden: true. 说明此路由不是菜单路由,不会显示在菜单中, 也不会显示在权限配置中. (权限配置按模块设计, 只有菜单模块)
              // 这种页面的权限,由按钮权限配置, 与hidden: true同时存在时生效. 当passport存在于权限表时, 用户具有本路由访问权限
              // 举例: 如果用户具有addGame权限, 那么就会显示`添加游戏`按钮, 同时可以访问passport为`addGame`的路由
              // 反之, 就不会显示`添加游戏`按钮, 也不可以访问passport为`addGame`的路由, 即使是通过收藏的网址也不可以.
              // 此类页面的细粒度权限, 交由菜单模块配置. 就是说, 不要在这里添加`permission: []`, GameEdit的permission, 应该在GameList处配置.
              passport: $p.game.addGame
            },
            component: () => import('@/views/game/game-edit/Index.vue')
          },
          {
            path: 'game-edit',
            name: 'GameEditIndex',
            hidden: true,
            meta: {
              title: 'GameEditIndex',
              // noCache: true,
              passport: $p.game.editGame
            },
            component: () => import('@/views/game/game-edit/Index.vue')
          },
          {
            path: 'wish-list',
            name: 'WishList',
            meta: {
              title: '游戏申请'
            },
            component: () => import('@/views/game/WishList')
          },
          {
            path: 'game-library-edit',
            name: 'GameLibraryEdit',
            hidden: true,
            meta: {
              title: '游戏编辑'
            },
            component: () => import('@/views/game-content/GameDetail')
          },
          {
            path: 'tag-manage',
            name: 'TagManage',
            meta: {
              title: '标签管理'
            },
            component: () => import('@/views/game-content/TagManage')
          },

          {
            path: 'list',
            name: 'VirtualKeyboardList',
            meta: {
              title: '官方键盘'
            },
            component: () => import('@/views/virtual-keyboard/index.vue')
          }
        ]
      },
      {
        path: 'CommodityManagement',
        redirect: 'CommodityManagement/index',
        name: 'CommodityManagement',
        alwaysShow: true,
        meta: {
          title: '交易配置',
          icon: 'balance-scale'
        },
        component: LayoutMain,
        children: [
          {
            path: 'recharge',
            name: 'AccountRecharge',
            meta: {
              title: 'AccountRecharge'
            },
            component: () => import('@/views/operational-tool/Recharge')
          },
          // {
          //   path: 'index',
          //   component: () => import('@/views/CommodityManagement/index'),
          //   name: 'CommodityManagement',
          //   meta: {
          //     title: '商品管理',
          //     noCache: true
          //   }
          // },
          {
            path: 'GoodsItemCreate',
            name: 'GoodsItemCreate',
            hidden: true,
            meta: {
              title: '创建新商品[支付]'
            },
            component: () => import('@/views/recharge-system/GoodsItemCreateOrEdit')
          },
          {
            path: 'GoodsItemEdit',
            name: 'GoodsItemEdit',
            hidden: true,
            meta: {
              title: '编辑商品[支付]'
            },
            component: () => import('@/views/recharge-system/GoodsItemCreateOrEdit')
          },
          // {
          //   path: 'index',
          //   component: () => import('@/views/CommodityManagement/index'),
          //   name: 'CommodityManagement',
          //   meta: {
          //     title: '商品管理',
          //     noCache: true
          //   }
          // },
          {
            path: 'subscriptionManagement',
            component: () => import('@/views/CommodityManagement/subscriptionManagement.vue'),
            name: 'subscriptionManagement',
            meta: {
              title: '订阅管理',
              noCache: true
            }
          },
          {
            path: 'topUpManagement',
            component: () => import('@/views/CommodityManagement/goodsManagement.vue'),
            props: { goodsType: 1 },
            name: 'topUpManagement',
            meta: {
              title: '时长管理',
              noCache: true
            }
          },
          {
            path: 'goldManagement',
            component: () => import('@/views/CommodityManagement/goodsManagement.vue'),
            props: { goodsType: 7 },
            name: 'goldManagement',
            meta: {
              title: '金币管理',
              noCache: true
            }
          },
          {
            path: 'PaymentModeConfiguration',
            component: () => import('@/views/CommodityManagement/paymentModeConfiguration.vue'),
            // props: { goodsType: 7 },
            name: 'PaymentModeConfiguration',
            meta: {
              title: '支付方式配置',
              noCache: true
            }
          },
          {
            path: 'SyncPrice',
            name: 'SyncPrice',
            meta: {
              title: '商品列表（老版本）'
            },
            component: () => import('@/views/recharge-system/SyncPrice')
          },
          {
            path: '/CommodityManagement/paymentPageConfigurationEdit/:id',
            hidden: true,
            component: () => import('@/views/CommodityManagement/paymentPageConfigurationEdit.vue'),
            // props: { goodsType: 7 },
            name: 'paymentPageConfigurationEdit',
            meta: {
              title: '编辑支付方式配置',
              noCache: true
            }
          },
          {
            path: 'paymentPageConfigurationAdd',
            hidden: true,
            component: () => import('@/views/CommodityManagement/paymentPageConfigurationAdd.vue'),
            // props: { goodsType: 7 },
            name: 'paymentPageConfigurationAdd',
            meta: {
              title: '新增支付方式配置',
              noCache: true
            }
          }
        ]
      },
      // ADVERTISING_SPACE,
      // 运营活动
      {
        path: 'operate-activity',
        name: 'operate-activity',
        alwaysShow: true,
        meta: {
          title: '运营活动',
          icon: 'line-chart'
        },
        component: LayoutMain,
        children: [
          {
            path: 'invite-friends',
            redirect: 'withdrawal',
            name: 'InviteFriends',
            alwaysShow: true,
            component: LayoutIndex,
            meta: {
              title: '好友邀请'
              // icon: 'handshake-o'
            },
            children: [
              {
                path: 'withdrawal',
                name: 'withdrawal',
                meta: {
                  title: '提现管理'
                },
                component: () => import('@/views/invite-friends/withdrawal')
              },
              {
                path: 'list',
                name: 'InviteFriendsList',
                meta: {
                  title: '邀请好友列表'
                },
                component: () => import('@/views/invite-friends/InviteFriendsList')
              }
            ]
          },
          {
            path: 'lucky-buy',
            component: LayoutIndex,
            redirect: 'lucky-buy/delivery-management',
            alwaysShow: true,
            meta: {
              title: '一元夺宝'
              // icon: 'star'
            },
            children: [
              {
                path: 'lucky-draw-management',
                name: 'LuckyDrawManagement',
                meta: {
                  title: '奖期管理'
                },
                component: () => import('@/views/lucky-buy/LuckyDrawManagement.vue')
              },
              {
                path: 'delivery-managemen',
                name: 'DeliveryManagement',
                meta: {
                  title: '发货管理'
                },
                component: () => import('@/views/lucky-buy/DeliveryManagement.vue')
              },
              {
                path: 'prize-managemen',
                name: 'PrizeManagement',
                meta: {
                  title: '奖品管理'
                },
                component: () => import('@/views/lucky-buy/PrizeManagement.vue')
              },
              {
                path: 'prize-edit',
                name: 'PrizeEdit',
                hidden: true,
                meta: {
                  title: '编辑奖品'
                },
                component: () => import('@/views/lucky-buy/CreateOrEditPrize.vue')
              },
              {
                path: 'prize-create',
                name: 'PrizeCreate',
                hidden: true,
                meta: {
                  title: '新建奖品'
                },
                component: () => import('@/views/lucky-buy/CreateOrEditPrize.vue')
              },
              {
                path: 'examine-and-verify',
                name: 'LuckyBuyExamineAndVerify',
                meta: {
                  title: '审核管理'
                },
                component: () => import('@/views/lucky-buy/examineAndVerify.vue')
              }
            ]
          },
          {
            path: 'e-commerce',
            component: LayoutIndex,
            redirect: 'e-commerce/sales-management',
            alwaysShow: true,
            meta: {
              title: '电商订单'
              // icon: 'cc-visa'
            },
            children: [
              {
                path: 'sales-management',
                name: 'commerce-sales-management',
                meta: {
                  title: '销售管理'
                },
                component: () => import('@/views/electronic-commerce/index.vue')
              },
              {
                path: 'order-list',
                name: 'commerce-order-list',
                meta: {
                  title: '订单列表'
                },
                component: () => import('@/views/electronic-commerce/OrderList.vue')
              }]
          },
          {
            path: 'e-entity',
            component: LayoutIndex,
            redirect: 'e-entity/goods-management',
            alwaysShow: true,
            meta: {
              title: '实物电商管理'
              // icon: 'cc-visa'
            },
            children: [
              {
                path: 'goods-management',
                name: 'goods-management',
                meta: {
                  title: '商品管理'
                },
                component: () => import('@/views/electronic-entity/goodsManagment.vue')
              },
              {
                path: 'order-list',
                name: 'order-list',
                meta: {
                  title: '订单管理'
                },
                component: () => import('@/views/electronic-entity/OrderList.vue')
              },
              {
                path: 'goods-item-edit',
                name: 'goods-item-edit',
                meta: {
                  title: '编辑商品'
                },
                hidden: true,
                component: () => import('@/views/electronic-entity/goodsCreateOrEdit.vue')
              },
              {
                path: 'goods-item-create',
                name: 'goods-item-create',
                meta: {
                  title: '添加商品'
                },
                hidden: true,
                component: () => import('@/views/electronic-entity/goodsCreateOrEdit.vue')
              }
            ]
          },
          {
            path: 'PointsMall',
            // redirect: 'game-library',
            name: 'PointsMall',
            alwaysShow: true,
            meta: {
              title: '积分商城'
              // icon: 'gamepad'
            },
            component: LayoutIndex,
            children: [
              {
                path: 'goods',
                name: 'goods',
                meta: {
                  title: '商品管理'
                },
                component: () => import('@/views/PointsMall/goods')
              },
              {
                path: 'order',
                name: 'order',
                meta: {
                  title: '订单中心'
                },
                component: () => import('@/views/PointsMall/order')
              }
            ]
          },
          {
            path: 'index',
            name: 'index',
            meta: {
              title: '签到管理'
            },
            component: () => import('@/views/CheckInManagement/index')
          }
          // {
          //   path: 'CheckInManagement',
          //   // redirect: 'game-library',
          //   name: 'CheckInManagement',
          //   alwaysShow: true,
          //   meta: {
          //     title: '签到管理',
          //     icon: 'gamepad'
          //   },
          //   component: LayoutMain,
          //   children: [

          //   ]
          // }
        ]
      },
      // 菜单配置
      {
        path: 'menu-configuration',
        name: 'menu-configuration',
        alwaysShow: true,
        meta: {
          title: '菜单配置',
          icon: 'toggle-off'
        },
        component: LayoutMain,
        children: [
          {
            path: 'bottom-menu',
            name: 'bottom-menu',
            meta: {
              title: '底部菜单'
            },
            component: () => import('@/views/menu-configuration/bottom-menu/bottom-menu.vue')
          },
          {
            path: 'profile',
            name: 'profile',
            meta: {
              title: 'Profile页面'
            },
            component: () => import('@/views/menu-configuration/profile/profile.vue')
          },
          {
            path: 'discover',
            name: 'discover',
            meta: {
              title: 'Discover页面'
            },
            component: () => import('@/views/menu-configuration/discover/discover.vue')
          },
          {
            path: 'library',
            name: 'library',
            meta: {
              title: 'Library页面'
            },
            component: () => import('@/views/menu-configuration/library/library.vue')
          },
          {
            path: 'menu-edit',
            name: 'MenuEdit',
            hidden: true,
            meta: {
              title: 'MenuEdit'
            },
            component: () => import('@/views/menu-configuration/bottom-menu/compontent/meauEdit.vue')
          },
          {
            path: 'discover-edit',
            name: 'discoverEdit',
            hidden: true,
            meta: {
              title: 'discoverEdit'
            },
            component: () => import('@/views/menu-configuration/discover/compontent/meauEdit.vue')
          },
          {
            path: 'library-edit',
            name: 'libraryEdit',
            hidden: true,
            meta: {
              title: 'libraryEdit'
            },
            component: () => import('@/views/menu-configuration/library/compontent/meauEdit.vue')
          },
          {
            path: 'profile-edit',
            name: 'profileEdit',
            hidden: true,
            meta: {
              title: 'profileEdit'
            },
            component: () => import('@/views/menu-configuration/profile/compontent/meauEdit.vue')
          }
        ]
      },
      // {
      //   // Notifications
      //   path: 'notifications',
      //   component: LayoutMain,
      //   redirect: 'notifications/list',
      //   alwaysShow: true,
      //   meta: {
      //     title: '运营推送',
      //     icon: 'star'
      //   },
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'NotificationsList',
      //       meta: {
      //         title: '运营推送'
      //       },
      //       component: () => import('@/views/notifications/index.vue')
      //     },
      //     {
      //       path: 'edit',
      //       name: 'NotificationsEdit',
      //       hidden: true,
      //       meta: {
      //         title: '编辑推送信息'
      //       },
      //       component: () => import('@/views/notifications/editOrCreate.vue')
      //     },
      //     {
      //       path: 'create',
      //       name: 'NotificationsCreate',
      //       hidden: true,
      //       meta: {
      //         title: '创建推送信息'
      //       },
      //       component: () => import('@/views/notifications/editOrCreate.vue')
      //     }
      //   ]
      // },

      // {
      //   path: 'virtual-keyboard',
      //   name: 'VirtualKeyboard',
      //   alwaysShow: true,
      //   component: LayoutMain,
      //   meta: {
      //     title: '虚拟键管理',
      //     icon: 'keyboard-o'
      //   },
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'VirtualKeyboardList',
      //       meta: {
      //         title: '虚拟键盘列表'
      //       },
      //       component: () => import('@/views/virtual-keyboard/index.vue')
      //     }
      //   ]
      // },

      // {
      //   path: 'BlackWhiteList',
      //   redirect: 'BlackWhiteList/index',
      //   name: 'BlackWhiteList',
      //   alwaysShow: true,
      //   meta: {
      //     title: '主包审核管理',
      //     icon: 'balance-scale'
      //   },
      //   component: LayoutMain,
      //   children: [
      //     {
      //       path: 'index',
      //       component: () => import('@/views/blacklist/BlackWhiteList'),
      //       name: 'BlackWhiteListMain',
      //       meta: {
      //         title: '黑白名单',
      //         noCache: true
      //       }
      //     },
      //     {
      //       path: 'AuditManagement',
      //       component: () => import('@/views/blacklist/AuditManagement'),
      //       name: 'AuditManagement',
      //       meta: {
      //         title: '审核开关',
      //         noCache: true
      //       }
      //     }
      //   ]
      // },

      {
        path: 'permission',
        redirect: 'permission/UserManagement',
        name: 'Permission',
        alwaysShow: true,
        meta: {
          title: 'Permission',
          icon: 'terminal'
        },
        component: LayoutMain,
        children: [
          {
            path: 'UserManagement',
            component: () => import('@/views/permission/UserManagement'),
            name: 'UserManagement',
            meta: {
              title: 'UserManagement',
              noCache: true
            }
          },
          {
            path: 'RoleManagement',
            component: () => import('@/views/permission/RoleManagement'),
            name: 'RoleManagement',
            meta: {
              title: 'RoleManagement',
              noCache: true
            }
          }
        ]
      }
    ]
  }
]
